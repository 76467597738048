import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import {
  Layout,
  Row,
  Col,
  BackTop,
  Button,
  Popover,
  Divider,
  Modal,
  Image,
  Switch,
} from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import LandingTeamSlider from "../../components/landingPages/landingTeamSlider";
import { FeatureGrid } from "../../components/landingPages/featureGrid";
import { TeamPlanListForPopUp } from "../../components/plans/plans";
import DemoInfo from "./demo-info";

const { Content } = Layout;

const FreePlanner = () => {
  const [videoPaused, setVideoPaused] = useState(true);
  const [paperSize, setPaperSize] = useState("US");
  const vidRef = useRef(null);
  const vidRef2 = useRef(null);

  const handlePlayVideo = () => {
    if (videoPaused === true) {
      vidRef.current.play();
      setVideoPaused(false);
    } else {
      setVideoPaused(true);
    }
  };

  const handlePlayVideo2 = () => {
    if (videoPaused === true) {
      vidRef2.current.play();
      setVideoPaused(false);
    } else {
      setVideoPaused(true);
    }
  };

  const handlePaperSize = () => {
    setPaperSize(!paperSize);
    if (paperSize === false) {
      setPaperSize("US");
    }
  };

  console.log(paperSize);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="DayViewer Free Online Planners are easy to use printable planners to help you manage your day and week with ease. Print and plan your goals daily and weekly and stay ahead of the curve."
        />
        <title>Free Online Planner</title>
      </Helmet>
      <Layout>
        <CommonHeader />
      </Layout>
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />

        <Content className="homepage-background">
          <Row>
            <div className="homepage-banner">
              <Col lg={12} xs={24}>
                <div className="homepage-jumbotron">
                  <div className="homepage-headlines">
                    {/* <h1>Powerful Team Task Management &amp; Organizer System</h1> */}
                    <h1>Free Printable Online Planners ⬇</h1>
                    <h2>Grab these free printable planners while you can.</h2>
                    {/* <h4>
                    DayViewer Team Rooms are a complete, easy to use online
                    calendar planner that helps you manage your schedule, plan
                    tasks and keep information organized with clarity and
                    efficiency.
                  </h4> */}
                    <div style={{ paddingTop: 20 }}>
                      <p>
                        If you would like to get into the habit of planning your
                        day, then grab these free planners to help you get
                        started. Amazing things happen and you will see a great
                        benefit of being organized daily.
                      </p>
                    </div>
                  </div>
                  <div>
                    While you are here, take a little time to check out how
                    DayViewer Online Planner can help your business or team
                    operate more efficiently.
                  </div>
                </div>
              </Col>
              <Col lg={12} xs={0}>
                {/* <LandingTeamSlider /> */}

                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://res.cloudinary.com/dayviewer1/image/upload/v1677781908/printable-planner/dayviewer-free-planner-printable-coffee_iqzqt8.webp"
                  ></source>
                  <source
                    type="image/png"
                    srcSet="https://res.cloudinary.com/dayviewer1/image/upload/v1677781908/printable-planner/dayviewer-free-planner-printable-coffee_iqzqt8.png"
                  ></source>
                  <Image
                    src="https://res.cloudinary.com/dayviewer1/image/upload/v1677781908/printable-planner/dayviewer-free-planner-printable-coffee_iqzqt8.png"
                    alt="Free Printable Planner"
                    title="Free Printable Planner"
                  />
                </picture>
              </Col>
            </div>
          </Row>
          <Row>
            <Col>
              <div
                className="homepage-banner"
                style={{
                  width: "100vw",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <video
                  style={{
                    cursor: "pointer",
                  }}
                  ref={vidRef2}
                  onClick={handlePlayVideo2}
                  preload="none"
                  controls="controls"
                  poster={`https://dayviewer.s3.us-west-2.amazonaws.com/img/home/dvnext/landing/Team-Video-Poster.png`}
                  // muted="muted"
                  // autoplay="autoplay"
                  // loop="loop"
                  width="90%"
                  name="DayViewer Online Calendar Planner"
                  src="https://res.cloudinary.com/dayviewer1/video/upload/v1669256364/DV-Team-Room-Ad_ysgc1g.mp4"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <div
              className="homepage-banner"
              style={{ textAlign: "left", padding: "5em" }}
            >
              <Col lg={12} xs={24}>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcSet="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/task-list-planner-home.webp"
                    ></source>
                    <source
                      type="image/png"
                      srcSet="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/task-list-planner-home.png"
                    ></source>
                    <Image
                      src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/landing/task-list-planner-home.png"
                      className="homepage-image"
                      width="100%"
                      height="100%"
                      alt="Online Team Task Planner"
                      title="Online Team Task Planner"
                    />
                  </picture>
                </div>
              </Col>
              <Col lg={12} xs={0}>
                <div className="homepage-jumbotron">
                  <div>
                    <h1>Online Team Calendar Planner</h1>
                    <h2>Give Your Team Clarity &amp; Focus</h2>
                    <ul style={{ listStyle: "none", textAlign: "left" }}>
                      <li>
                        <h4>
                          Improve team work by scheduling and planning work more
                          efficiently.{" "}
                        </h4>
                        DayViewer helps visualize your work and time, presenting
                        task and other information at a glance.
                      </li>
                      <li>
                        <h4>
                          Keep an eye on the progress of jobs and tasks easily.
                        </h4>{" "}
                        Allow team members to comment directly on tasks and
                        entries, greatly reducing the need for email reports and
                        meetings.
                      </li>
                      <li>
                        <h4>Use For Any Type Of Business</h4> In fact anyone
                        that requires time and task management in a clear, easy
                        to use &amp; flexible solution. DayViewer is private,
                        secure and always improving.
                      </li>
                    </ul>
                    <div style={{ textAlign: "center" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Start Planning Online
                        </a>
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Row>
          <Row>
            <div
              className="homepage-banner"
              style={{ textAlign: "left", padding: "5em" }}
            >
              <Row>
                <Col lg={12} xs={24}>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <video
                      style={{ cursor: "pointer" }}
                      ref={vidRef}
                      onClick={handlePlayVideo}
                      preload="none"
                      controls="controls"
                      width="100%"
                      poster="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image-Play.png"
                      // muted="muted"
                      // autoplay="autoplay"
                      // loop="loop"
                      name="Online Work Scheduler"
                      src="https://res.cloudinary.com/dayviewer1/video/upload/v1669137012/DV_fast_planner_scheduling-bg-music_q7v3xb.mp4"
                    />
                  </div>
                </Col>
                <Col lg={12} xs={0}>
                  <div
                    style={{
                      paddingLeft: 20,
                      textAlign: "left",
                    }}
                  >
                    <h2>
                      DayViewer Fast Planner for Efficient Work Scheduling
                    </h2>
                    <div>
                      <p>
                        If you have tasks that are commonly set, it's efficient
                        to create templates. This way, all the main information
                        is pre-filled, meaning that it doesn't need to manually
                        be entered each time.
                      </p>
                      <p>
                        Once the templates are created, simply drag and drop
                        into the day boxes to set them. Our Fast Planner
                        interface can save a lot of time and repetitive
                        scheduling. It also makes it much easier to see what is
                        going on within that week at-a-glance.
                      </p>
                      <p>
                        Use the DayViewer Team Planner system to schedule sales
                        calls or visits, content planning, appointments,
                        meetings, jobs and more. - it's completely flexible for
                        your specific needs.
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Create Your Team Calendar
                        </a>
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Divider />
          <Row>
            <div
              className="homepage-banner"
              style={{ textAlign: "left", padding: "5em" }}
            >
              <Row>
                <Col lg={12} xs={0}>
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <h2>
                      Select By Label - Displays All Labelled Entries In One
                      Place
                    </h2>
                    <div>
                      <p>
                        When you have multiple projects going on, labelling your
                        entries becomes pretty important. Labelling ensures your
                        related entries are tied together to a particular
                        keyword.
                      </p>
                      <p>
                        Labels also improve information recall, visibility and
                        search ability by giving you filtering and color-coding.
                      </p>

                      <p>
                        Our labels section is a special feature that also
                        incorporates a quick filter showing you all the various
                        entries created with a particular label.
                      </p>
                      <p>
                        It is a super convenient way to view all your related
                        tasks and other entries together on one page.
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Create Your Team Planner
                        </a>
                      </Button>
                    </div>
                  </div>
                </Col>

                <Col lg={12} xs={24}>
                  <div>
                    <picture>
                      <Image
                        src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-labels.png"
                        className="homepage-image"
                        width="100%"
                        height="100%"
                        alt="Online Organizer For Teams"
                        title="Online Organizer For Teams"
                      />
                    </picture>
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col lg={12} xs={24}>
                  <div>
                    <picture>
                      <Image
                        //src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/landing/task-list-planner-home.png"
                        src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/dv-team-cards-progress.gif"
                        className="homepage-image"
                        width="100%"
                        height="100%"
                        alt="Online Team Task Planner"
                        title="Online Team Task Planner"
                      />
                    </picture>
                  </div>
                </Col>
                <Col lg={12} xs={0}>
                  <div
                    style={{
                      paddingLeft: 20,
                      textAlign: "left",
                    }}
                  >
                    <h2>One-Click Team Task Status Filtering</h2>
                    <div>
                      <p>
                        Often you just want to see how tasks are progressing.
                        The task cards view shows all tasks on one board.
                      </p>
                      <p>
                        At the top of this board is a set of statuses which can
                        be selected. So you can quickly jump through which tasks
                        are at which stage.
                      </p>
                      <p>
                        The cards also will highlight which tasks are overdue
                        automatically.
                      </p>
                      <p>
                        It is good practice for the team members that are
                        assigned tasks to update progress regularly, it assists
                        forward planning and helps the project or entire
                        business stay on track.
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Join &amp; Try For Free
                        </a>
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{ textAlign: "center", margin: 50 }}>
                <div>
                  <h1>Thanks for visiting, Here are the free planners!</h1>
                </div>
                <div>
                  Select Paper Size Preference (US Letter / A4){" "}
                  <span>
                    <Switch
                      onChange={handlePaperSize}
                      checkedChildren="A4"
                      unCheckedChildren="US"
                    />
                  </span>
                </div>
              </div>
            </Col>

            <Col lg={12} xs={24}>
              <div style={{ margin: 10, textAlign: "center" }}>
                <div>
                  <Image
                    src="https://dayviewer.s3.us-west-2.amazonaws.com/img/home/dvnext/printplanners/DayViewer+Daily+Planner.png"
                    width={300}
                  />
                </div>
                <div>
                  {paperSize === "US" ? (
                    <Button className="homepage-signup-btn">
                      <a
                        href="#"
                        onClick={() =>
                          window.open("https://dayv.me/C8YcLm", "_blank")
                        }
                      >
                        Download Daily Planner PDF <br />
                        (US Letter)
                      </a>
                    </Button>
                  ) : (
                    <Button className="homepage-signup-btn">
                      <a
                        href="#"
                        onClick={() =>
                          window.open(
                            "https://dayv.me/dailyplanner-a4-pdf",
                            "_blank"
                          )
                        }
                      >
                        Download Daily Planner PDF <br />
                        (A4 Format)
                      </a>
                    </Button>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div style={{ margin: 10, textAlign: "center" }}>
                <div>
                  <Image
                    src="https://dayviewer.s3.us-west-2.amazonaws.com/img/home/dvnext/printplanners/DayViewer+Weekly+Planner.png"
                    width={300}
                  />
                </div>
                <div>
                  {paperSize === "US" ? (
                    <Button className="homepage-signup-btn">
                      <a
                        href="#"
                        onClick={() =>
                          window.open("https://dayv.me/1Ke4EG", "_blank")
                        }
                      >
                        Download Weekly Planner PDF <br />
                        (US Letter)
                      </a>
                    </Button>
                  ) : (
                    <Button className="homepage-signup-btn">
                      <a
                        href="#"
                        onClick={() =>
                          window.open(
                            "https://dayv.me/weeklyplanner-a4-pdf",
                            "_blank"
                          )
                        }
                      >
                        Download Weekly Planner PDF <br />
                        (A4 Format)
                      </a>
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div
                style={{
                  padding: "5rem",
                  textAlign: "center",
                }}
              >
                <h3>
                  Share these planners in your social networks, messaging groups
                  and around the workplace!
                </h3>
                <h4 style={{ color: "darkgrey" }}>
                  Help everyone to plan and get more organized!
                </h4>
              </div>
            </Col>
          </Row>
          <Row>
            <FeatureGrid />
          </Row>
        </Content>
      </Layout>
      <Layout>
        <CommonFooter />
      </Layout>
    </div>
  );
};

export default FreePlanner;
